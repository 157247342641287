<template>
  <div class="login-form" @keyup.enter="log_in()">
    <DxTextBox
      v-model="login"
      label="Email"
    />
    <br>
    <DxTextBox
      v-model="password"
      label="Senha"
      :mode="passwordMode"
    >
    <DxTextBoxButton
      :options="passwordButton"
      name="password"
      location="after"
    />
    </DxTextBox>
    <br>
    <br>
    <DxButton
      icon="user"
      type="success"
      text="Login"
      @click="log_in()"
    />
    <br>
    <br>
    <div class="header">
      <span class="description-help">Precisa de ajuda? Entre em contato</span>
      <DxButton
        icon="errorcircle"
        type="default"
        text="Suporte"
        @click="showSuporteModal"
      />
    </div>
    <DxToast
      :visible="isVisible"
      :message="message"
      :type="type"
    />
  </div>
</template>

<script>
import api from '../api/api';
import { DxTextBox, DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import { DxToast } from 'devextreme-vue/toast';

export default {
  data() {
    return {
      isVisible: false,
      message: '',
      type: 'info',
      loading: false,
      login: '',
      password: '',
      rememberMe: '',
      passwordMode: 'password',
      showModal: false,
      passwordButton: {
        icon: "unlock",
        onClick: () => {
          this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
        },
      }
    };
  },
  created(){
    document.title = 'Login'
  },
  methods: {
    async log_in() {
      this.loading = true;
      var result = await api.login('login', [this.login, this.password]);
      if (result.code == 0) {
        localStorage.clear();
        localStorage.setItem("logado", 'S');
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("cd_usuario", result.user.cd_usuario);
        var html = document.getElementsByTagName('html')[0]
        html.style.setProperty("--main_color", result.user.cor)
        this.loading = false;
        this.$router.replace(`/${result.user.nm_tela_inicio}`);
      } else {
        this.message = result.Mensagem;
        this.type = 'error';
        this.isVisible = true;
      }
      this.loading = false;
    },
    async showSuporteModal() {
      if (this.login === '' || this.password === '') {
        this.message = 'Preencha o email e a senha para acessar o suporte';
        this.type = 'error';
        this.isVisible = true;
      } 
      else {
        var result = await api.login('login', [this.login, this.password]);
        if (result.code == 0) {
          localStorage.clear();
          this.$router.push('/suporte');
          localStorage.setItem("logado", 'S');
          localStorage.setItem("user", JSON.stringify(result.user));
          localStorage.setItem("cd_usuario", result.user.cd_usuario);
        }
      }
      if(result.code != 0){
        this.message = result.Mensagem;
        this.type = 'error';
        this.isVisible = true;
      }
    },
    closeModal() {
      localStorage.clear();
      this.showModal = false;
    },
  },
  components: {
    DxTextBox,
    DxTextBoxButton,
    DxToast,
    DxButton
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.close-button {
  margin-top: 20px;
  width: 100px;
}
.description-help{
  color:rgb(128, 126, 126)
}
</style>