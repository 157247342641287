<template>
    <div>
        <label for="images" class="drop-container" id="dropcontainer">
            <span class="drop-title" v-if="showLink">
                Link para acesso à imagem: <a :href="linkImg" class="imglink">{{ linkImg }}</a>
            </span>
            <span>Selecione o arquivo</span>
            <input type="file" id="file-uploader" @change="showimg">
            <img :src="srcimg"  height="400">
        </label>
        <div class="box-buttons">
            <DxButton 
                class="DxButton"
                icon="upload"
                type="success"
                text="Confirmar Upload"
                @click="upload_arquivo"
            />
            <DxButton 
                class="DxButton"
                icon="paste"
                type=""
                text="Copiar Link para área de transferência"
                @click="copy_link"
            />
            <DxButton 
                class="DxButton"
                icon="close"
                type="danger"
                text="Limpar"
                @click="limpar"
            />
        </div>
    </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import api from '../api/api'
export default{
    data(){
        return{
            formData: {},
            srcimg: '',
            showLink: false,
            linkImg: ''
        }
    },
    components:{
        DxButton,
    },
    methods:{
        async upload_arquivo(){
            this.showLink = true
            var arq = document.getElementById('file-uploader').files[0]
            //var empresa = localStorage.getItem('empresa')
            var result = await api.upload_file(arq,'upload-file/cws')
            this.linkImg = result.url
        },
        showimg(){
            var arq = document.getElementById('file-uploader').files[0]
            console.log('ARQS',arq)
            this.srcimg = URL.createObjectURL(arq)
        },
        copy_link(){
            navigator.clipboard.writeText(this.linkImg)
        },
        limpar(){
            document.getElementById("file-uploader").value = "";
            this.linkImg = ''
            this.srcimg = ''
            this.showLink = false
        }
    }
}
</script>
<style>
input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.imglink{
    color: #084cdf;
}
</style>