<template>
    <div>
        <div class="div_pai">
            <div class="planta_sonda" v-if="load">
                <div class="fases_cima_sonda">
                    <div class="portal_fases"/>
                    <div class="portas">
                        <div class="porta"></div>
                        <div class="espaco_porta"></div>
                        <div class="porta"></div>
                    </div>
                    <div class="fase1">
                        <div v-for="coluna_sonda in fases[0].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                            <div v-for="linha_sonda in fases[0].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                <div v-for="localizacao in localizacao(0,coluna_sonda, linha_sonda)" :key="localizacao">
                                    <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                        <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                            .
                                            <DxTooltip :hide-on-outside-click="true" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="portas">
                        <div class="porta"></div>
                        <div class="espaco_porta"></div>
                        <div class="porta"></div>
                    </div>
                    <div class="fase2">
                        <div v-for="coluna_sonda in fases[1].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                            <div v-for="linha_sonda in fases[1].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                <div v-for="localizacao in localizacao(1,coluna_sonda, linha_sonda)" :key="localizacao">
                                    <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                        <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                            .
                                            <DxTooltip :hide-on-outside-click="true" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="portas">
                        <div class="porta"></div>
                        <div class="espaco_porta"></div>
                        <div class="porta"></div>
                    </div>
                    <div class="fase3">
                        <div v-for="coluna_sonda in fases[2].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                            <div v-for="linha_sonda in fases[2].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                <div v-for="localizacao in localizacao(2,coluna_sonda, linha_sonda)" :key="localizacao">
                                    <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                        <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                            .
                                            <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mdas">
                        <div class="mda1">
                            <div v-for="coluna_sonda in fases[3].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                                <div v-for="linha_sonda in fases[3].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                    <div v-for="localizacao in localizacao(3,coluna_sonda, linha_sonda)" :key="localizacao">
                                        <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                            <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                                .
                                                <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="divisao_mda" />
                        <div class="mda2">
                            <div v-for="coluna_sonda in fases[4].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                                <div v-for="linha_sonda in fases[4].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                    <div v-for="localizacao in localizacao(4,coluna_sonda, linha_sonda)" :key="localizacao">
                                        <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                            <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                                .
                                                <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="portas">
                        <div class="porta"></div>
                        <div class="espaco_porta"></div>
                        <div class="porta"></div>
                    </div>
                </div>
                <div class="corredor">
                    <div class="parede_corredor"/>
                    <div class="corredor_meio"/>
                    <div class="parede_corredor"/>
                    <div class="corredor_meio"/>
                    <div class="parede_corredor"/>
                    <div class="corredor_meio" style="width: 408px;"/>
                    <div class="parede_corredor"/>
                </div>
                <div class="fases_baixo">
                    <div class="pop1">
                        <div class="portas_baixo">
                            <div class="parede"/>
                            <div class="porta_pop"/>
                            <div class="parede"/>
                        </div>
                        <div v-for="coluna_sonda in fases[5].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                            <div v-for="linha_sonda in fases[5].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                <div v-for="localizacao in localizacao(5,coluna_sonda, linha_sonda)" :key="localizacao">
                                    <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                        <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                            .
                                            <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sala_entre_pop1eestoque1">
                    </div>
                    <div class="estoque1">
                        <div class="portas_baixo">
                            <div class="porta_estoque"/>
                            <div class="parede"/>
                        </div>
                    </div>
                    <div class="pre_site">
                        <div class="portas_baixo">
                            <div class="parede"/>
                            <div class="porta_pre_site"/>
                            <div class="parede"/>
                        </div>
                    </div>
                    <div class="sala_entre_presiteestoque2">
                        <div class="sala1">
                            <div class="porta_sala1"></div>
                            <div class="parede_sala1"></div>
                            <div class="porta_sala1"></div>
                        </div>
                        <div class="sala2">
                            <div class="porta_sala2"></div>
                            <div class="parede_sala2"></div>
                            <div class="porta_sala2"></div>
                        </div>
                    </div>
                    <div class="estoque2">
                        <div class="portas_baixo">
                            <div class="porta_estoque"/>
                            <div class="parede"/>
                        </div>
                        <div class="coluna_sonda"/>
                    </div>
                    <div class="desembalagem2">
                        <div class="portas_baixo">
                            <div class="parede"/>
                            <div class="porta_estoque"/>
                            <div class="parede"/>
                        </div>
                    </div>
                    <div class="divisoria_desembalagem2">
                        <div class="parede"/>
                        <div class="divisoria"/>
                        <div class="parede"/>
                    </div>
                    <div class="continuacao_desembalagem2">
                        <div class="portas_baixo">
                            <div class="parede"/>
                            <div class="parede"/>
                        </div>
                    </div>
                    <div class="salaentredesembalagem2epop2">
                        <div class="sala">
                            <div class="porta_sala"></div>
                            <div class="parede_sala"></div>
                            <div class="porta_sala"></div>
                        </div>
                    </div>
                    <div class="pop2">
                        <div class="portas_baixo">
                            <div class="parede"/>
                            <div class="porta_pop"/>
                            <div class="parede"/>
                        </div>
                        <div v-for="coluna_sonda in fases[9].qt_colunas" :key="coluna_sonda" class="coluna_sonda">
                            <div v-for="linha_sonda in fases[9].qt_linhas" :key="linha_sonda" class="linha_sonda">
                                <div v-for="localizacao in localizacao(9,coluna_sonda, linha_sonda)" :key="localizacao">
                                    <div class="localizacao_sonda" @drop="onDrop($event, localizacao.cd_fase_localizacao)" @dragover.prevent @dragenter.prevent>
                                        <div class="item_sonda" :style="{width: (localizacao.vl_largura==undefind?60:localizacao.vl_largura *13)/60+'px', height: (localizacao.vl_profundidade==undefind?60:localizacao.vl_profundidade *13)/60+'px', backgroundColor: localizacao.nm_cor==undefined?'transparent':localizacao.nm_cor, border: 'none'}" @click="localizacao_click(localizacao)" @mouseover="hover(localizacao)" :id="'posicao'+localizacao.cd_fase_localizacao">
                                            .
                                            <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+localizacao.cd_fase_localizacao" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dx-card" v-if="show_ativo_localizacao" style="text-align: center">
                <div class="sair_ativo_localizacao" @click="show_ativo_localizacao = false">FECHAR</div>
                <br>
                <br>
                <ativos_info :ativo="ativo_localizacao_selecionada[0]" :ativos="ativos_localizacao"/>
                <br>
                <DxButton
                    v-if="ativo_localizacao_selecionada[0].qt_us != 1"
                    icon="verticalaligntop"
                    type="default"
                    text="Visualizar Rack" 
                    @click="popup_open()"/>
                <DxButton
                    icon="remove"
                    type="warning"
                    text="Remover ativo desta posição" 
                    @click="remover_ativo()"/>
            </div>
            <div class="dx-card responsive-paddings" v-if="!show_ativo_localizacao && load" style="width: 100%; margin-left: 10px; text-align: center">
                <div>
                    <DxSelectBox
                        placeholder="Fases"
                        :data-source="fases_select"
                        v-model="fase_selecionada"
                        display-expr="nm_fase"
                        value-expr="cd_fase"
                        search-mode="contains"
                        search-expr="nm_fase"
                        :search-enabled="true"
                        @value-changed="onChangeFase"
                    />
                </div>
                <div>
                    TOTAL DE EQUIPAMENTOS: {{fase_selecionada_grafico.qt_equipamento}} <br>
                    RACKS INSTALADOS: {{ fase_selecionada_grafico.qt_racks }} <br>
                    <DxPieChart
                      id="pie"
                      :data-source="infos_fase"
                      type="doughnut"
                      palette="Soft Pastel"
                      :title="`TOTAL DE U´S: ${fase_selecionada_grafico.qt_total}`"
                    >
                      <DxSeries
                        argument-field="nm"
                        value-field="qt"
                      >
                        <DxLabel
                          :visible="true"
                          :customize-text="({ valueText, percentText }) => `${valueText} (${percentText})`"
                          position="columns"
                        >
                          <DxConnector
                            :visible="true"
                            :width="0.5"
                          />
                          <DxFont :size="16"/>
                        </DxLabel>
                      </DxSeries>
                      <DxLegend
                        :column-count="4"
                        orientation="horizontal"
                        item-text-position="right"
                        horizontal-alignment="center"
                        vertical-alignment="bottom"
                      />
                      <DxExport :enabled="true"/>
                    </DxPieChart>
                </div>
                <hr>
                <div style="text-align: center;">
                    ATIVOS SEM POSIÇÃO
                </div>
                <div v-for="(item, index) in ativos_sem_posicao" :key="item" style="display: flex;">
                    <div draggable="true" v-on:dragstart="startDrag($event, item)" :style="{width: (item.vl_largura *13)/60+'px', height: (item.vl_profundidade *13)/60+'px', backgroundColor: item.nm_cor, margin: 2+'px'}" @mouseover="hover(item)" :id="'posicao'+item.cd_ativo"/>
                    <DxButton
                        icon="pulldown"
                        type="default"
                        style="height: 20px; width: 20px; background-color: blue"
                        @click="vira_item(index, item)"/>
                    <DxTooltip :hide-on-outside-click="false" :target="'#posicao'+item.cd_ativo" show-event="mouseenter" hide-event="mouseleave"> <img height="100px" :src="ativo_tooltip.nm_caminho_imagem"/> <br> <b>Localização: </b>{{ ativo_tooltip.nm_fase_localizacao }}<br><b>Ativo: </b>{{ ativo_tooltip.nm_descricao }}<br><b>Capacidade: </b>{{ ativo_tooltip.qt_us }}<br><b>Livres: </b>{{ (ativo_tooltip.qt_us-ativo_tooltip.ocupados) }}<br><b>Ocupados: </b>{{ ativo_tooltip.ocupados }}<br></DxTooltip>
                    <br>
                </div>
            </div>
        </div>
        <DxPopup
          v-if="open_popup"
          :visible="open_popup"
          height="100%"
          width="100%"
        >
            <DxToolbarItem
                widget="dxButton"
                toolbar="top"
                location="before"
                :options="button_cancelar"
            />
                <fase_localizacao :fase_localizacao="localizacao_selecionada" :ativos="ativos_localizacao" :cd_fase_localizacao="localizacao_selecionada.cd_fase_localizacao"/>
        </DxPopup>
    </div>
</template>
<script>
import api from '@/api/api'
import ativos_info from './ativo_selecionado_sonda'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import DxButton from 'devextreme-vue/button'
import fase_localizacao from './fase_localizacao'
import DxSelectBox from 'devextreme-vue/select-box';
import { DxTooltip } from 'devextreme-vue/tooltip';
import DxPieChart, {
  DxSeries,
  DxLegend,
  DxLabel,
  DxConnector,
  DxFont,
  DxExport,
} from 'devextreme-vue/pie-chart';
export default{
    data(){
        return{
            fases: [],
            load: false,
            show_ativo_localizacao: false,
            localizacao_selecionada: {},
            ativo_localizacao_selecionada: {},
            ativos_localizacao: [],
            ativo_tooltip: {},
            show_add_ativo: false,
            open_popup: false,
            button_cancelar: {
                width: 15,
                icon: 'close',
                type:'danger',
                onClick: ()=>{
                    this.open_popup = false
                    document.body.style.zoom = "80%"
                }
            },
            ativos_sem_posicao: [],
            ativo_dnd: 0,
            status_tela: true,
            infos_fase: [],
            fase_selecionada: 9999,
            fases_select: [],
            fase_selecionada_grafico: {},
        }
    },
    async mounted(){
        if(this.status_tela){
            document.body.style.zoom = "80%"
            await this.carrega_dados()
        }
    },
    beforeUnmount(){
        document.body.style.zoom = "100%"
    },
    components:{
        ativos_info,DxPopup, DxToolbarItem,DxButton,fase_localizacao,DxTooltip,DxSeries,DxLegend,DxLabel,DxConnector,DxFont,DxExport,DxPieChart,DxSelectBox
    },
    methods:{
        async carrega_dados(){
            //CARREGAR TODAS AS FASES
            this.fases = await api.get('consulta_fase_com_localizacoes',[])
            this.fases.forEach((element, index) => {
                this.fases[index].localizacoes = JSON.parse(this.fases[index].localizacoes)
            })
            this.ativos_sem_posicao = await api.get('consulta_ativos_sem_posicao', [])
            this.fases_select = await api.get('consulta_grafico_mapa', [])
            console.log('FASE SELECT',this.fases_select)
            this.fase_selecionada_grafico = this.fases_select.filter((element)=> element.cd_fase == this.fase_selecionada)[0]
            this.infos_fase = [
                {
                    nm: 'U´s Livres',
                    qt: this.fase_selecionada_grafico.qt_livre
                },
                {
                    nm: 'U´s Ocupadas',
                    qt: this.fase_selecionada_grafico.qt_ocupadas
                }
            ]
            this.load = true
            //console.log('FASES', this.fases)
        },
        onChangeFase(){
            this.fase_selecionada_grafico = this.fases_select.filter((element)=> element.cd_fase == this.fase_selecionada)[0]
            this.infos_fase = [
                {
                    nm: 'U´s Livres',
                    qt: this.fase_selecionada_grafico.qt_livre
                },
                {
                    nm: 'U´s Ocupadas',
                    qt: this.fase_selecionada_grafico.qt_ocupadas
                }
            ]
            console.log('FASE SELECIONADA GRÁFICO', this.fase_selecionada_grafico)
        },
        localizacao(index, coluna, linha){
            var loc = this.fases[index].localizacoes.filter(e => e.coluna == coluna && e.linha == linha)
            //console.log('Localização', loc)
            return loc
        },
        async localizacao_click(localizacao){
            this.ativo_localizacao_selecionada = await api.get('consulta_ativo_item', [localizacao.cd_ativo])
            this.ativos_localizacao = await api.get('consulta_ativos_fase_localizacao', [localizacao.cd_fase_localizacao])
            if(localizacao.cd_ativo != undefined){
                this.localizacao_selecionada = localizacao
                this.show_ativo_localizacao = true
                this.show_add_ativo = false
            }
            else{
                this.show_add_ativo = true
                this.show_ativo_localizacao = false
            }
        },
        popup_open(){
            document.body.style.zoom = "100%"
            this.open_popup = true
        },
        //DnD events
        startDrag(evnt, item){
            this.ativo_dnd = item.cd_ativo
            evnt.dataTransfer.dropEffect = 'move'
            evnt.dataTransfer.effectAllowed = 'move'
            evnt.dataTransfer.setData('itemID', item)
        },
        async onDrop(evnt, posicao){
            await api.get('add_ativo_fase_localizacao', [this.ativo_dnd, posicao])
            this.carrega_dados()
        },
        async vira_item(index, item){
            var profundidade, largura
            profundidade = this.ativos_sem_posicao[index].vl_profundidade
            largura = this.ativos_sem_posicao[index].vl_largura
            this.ativos_sem_posicao[index].vl_largura = profundidade
            this.ativos_sem_posicao[index].vl_profundidade = largura
            await api.get('vira_item', [largura, profundidade, item.cd_item])
        },
        hover(localizacao){
            //console.log('HOVER LOCALIZACAO',localizacao)
            this.ativo_tooltip = localizacao
        },
        remover_ativo(){
            api.get('remove_ativo_fase_localizacao', [this.localizacao_selecionada.cd_fase_localizacao])
            this.carrega_dados()
        }
    }
}
</script>
<style>
.sair_ativo_localizacao{
    height: 30px;
    width: 100%;
    background-color: blue;
    color: white;
    justify-content: center;
    align-items: center;
    padding: auto;
    cursor: pointer;

}
.div_pai{
    display: flex;
    margin-top: 25px
}
.portal{
    background-color: green;
    width: 7px;
    height: 20px;
}
.planta_sonda{
    border: 5px solid blue;
    background-color: white;
    margin-left: 2vh;
    width:fit-content;
    padding-right: 5vh;
}
.porta{
    height: 280px;
    width: 5px;
    background-color: blue;
    border-radius: 20px 20px;
    margin-top: -5px
}
.espaco_porta{
    height: 57px;
}
.fases_cima_sonda{
    display: flex;
    margin-left: 0vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.portal_fases{
    margin-top: 360px;
    width: 8vh;
    height: 15px;
    border-top: 5px dotted green;
}
.corredor{
    margin-left: 8vh;
    display: flex;
}
.parede_corredor{
    height: 20px; /* Altura da linha */
    width: 5px;
    background-color: blue;
    border-radius: 20px 20px 0px 0px;
    margin-top: -15px
}
.corredor_meio{
    height: 5px; /* Altura da linha */
    width: 340px;
    background-color: blue;
}
.fases_baixo{
    display: flex;
    margin-top: 5vh;
}
.fase1{
    margin-top: 10px;
    border: 1px solid blue;
    margin-bottom: 16px;
    border-top: 5px solid blue;
    border-bottom: 5px solid blue;
}
.fase2{
    margin-top: 10px;
    border: 1px solid blue;
    margin-bottom: 16px;
    border-top: 5px solid blue;
    border-bottom: 5px solid blue;
}
.fase3{
    margin-top: 10px;
    border: 1px solid blue;
    margin-bottom: 16px;
    border-top: 5px solid blue;
    border-bottom: 5px solid blue;
}
.mdas{
    margin-top: 10px;
}
.divisao_mda{
    height: 51px;
    border-right: 5px dotted green;
}
.mda1{
    border: 1px solid blue;
    border-top: 5px solid blue;
}
.mda2{
    border: 1px solid blue;
    border-bottom: 5px solid blue;
}
.linha_sonda{
    text-align: center;
}
.coluna_sonda{
  display: inline-block;
}
.localizacao_sonda{
    margin: 0;
    border: 0.1vh solid #c2c2c2;
    height: 13px;
    width: 13px;
    font-size: 3px;
}
.sala_entre_pop1eestoque1{
    width: 75px;
    border-top: 5px solid blue;
}
.sala_entre_presiteestoque2{
    display: flex;
    .sala1{
        margin-top: 30px;
        display: flex;
        border-right: 5px solid blue;
    }
    .parede_sala1{
        width: 20px;
    }
    .porta_sala1{
        width: 10px;
        height: 5px;
        background-color: blue;
    }
    .sala2{
        margin-left: 20px;
        display: flex;
        border-left: 5px solid blue;
        border-right: 5px solid blue;
    }
    .parede_sala2{
        width: 25px;
    }
    .porta_sala2{
        width: 10px;
        height: 5px;
        background-color: blue;
    }
}
.pop1{
    border-right: 5px solid blue;
    .parede{
        height: 5px;
        background-color: blue;
        width: 48px;
    }
}
.pop2{
    border-right: 5px solid blue;
    .parede{
        height: 5px;
        background-color: blue;
        width: 48px;
    }
}
.portas_baixo{
    display: flex;
}
.portas{
    align-items: end;
    text-align: right;
}
.porta_pop{
    width: 35px;
    border-top: 5px dotted green;
}
.porta_estoque{
    width: 43px;
    border-top: 5px dotted green;

}
.estoque1{
    border-left: 5px solid blue;
    border-right: 5px solid blue;
    .parede{
        height: 5px;
        background-color: blue;
        width: 48px;
    }
}
.estoque2{
    border-right: 5px solid blue;
    .parede{
        height: 5px;
        background-color: blue;
        width: 48px;
    }
}
.desembalagem2{
    .parede{
        height: 5px;
        background-color: blue;
        width: 43.5px;
    }
}
.divisoria_desembalagem2{
    .parede{
        height: 55px;
        width: 5px;
        background-color: blue;
    }
    .divisoria{
        height: 30px
    }
}
.continuacao_desembalagem2{
    width: 180px;
    border-right: 5px solid blue;
    border-top: 5px solid blue;
}
.porta_pre_site{
    width: 43px;
    border-top: 5px dotted green;
}
.pre_site{
    border-right: 5px solid blue;
    .parede{
        height: 5px;
        background-color: blue;
        width: 24px;
    }
}
.salaentredesembalagem2epop2{
    border-right: 5px solid blue;
    .sala{
        display: flex;
        border-left: 5px solid blue;
    }
    .parede_sala{
        width: 25px;
    }
    .porta_sala{
        width: 10px;
        height: 5px;
        background-color: blue;
    }
}
.item_sonda{
    position: relative;
    border: 0.5vh solid blue;
    cursor: pointer;
}
.item_sonda:active{
    background-color: blue;
}
</style>