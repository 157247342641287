<template>
  <div class="post-container">
    <!-- Adicionado o @click na imagem -->
    <img :src="post.nm_imagem" class="top-div" @click="navigateToPost(post.cd_post)"/>
    
    <div class="bottom-div">
      <div class="classificacao">
        <p class="classificacao-texto">{{ post.nm_classificacao }}</p>
      </div>

      <h1 class="post-titulo">{{ post.nm_titulo }}</h1>

      <p class="post-subtitulo">{{ post.nm_subtitulo }}</p>

      <div class="lermais">
        <button @click="navigateToPost(post.cd_post)" class="botao-leiamais">Leia Mais</button>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import router from '@/router';
  
  export default {
    props: {
      post: Object
    },
    methods: {
      navigateToPost(id) {
        const url = this.$router.resolve({ path: `/blog/post/${id}` }).href;
        window.open(url, '_blank');
      },
  
      navigateToBlog() {
        router.push({ name: 'blog' });
      }
    }
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');
  
  .post-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 450px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
    position: relative;
    font-family: 'Poppins', sans-serif; /* Definindo Poppins como a fonte */
  }

  img {
  cursor: pointer;
  transition: filter .5s ease;
}

img:hover {
  filter: brightness(0.75);
}

  .post-container:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .top-div {
    width: 100%;
    height: 40%;
    background-size: cover;
    background-position: center;
    border-bottom: 2px solid #f0f0f0;
  }
    
  .bottom-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
  }
  
  .classificacao-texto {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #242424;
    margin-bottom: 5px;
    font-style: italic;
    text-align: center;
    background-color: #469bf71f;
    padding: 2px 20px;
    font-weight: 500;
    border-radius: 1rem 0 1rem;
  }
  
  .post-titulo {
    font-size: 22px;  
    color: #333;
    font-weight: 600;
    font-family: 'Poppins', sans-serif; /* Poppins aplicada */
  }
  
  .post-subtitulo {
    padding: 0 1rem;
    font-size: 15px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; 
    line-height: 1.5em; 
    max-height: 6em; 
    word-break: break-word;
    text-align: justify;
    text-align-last: left;
    font-family: 'Poppins', sans-serif; /* Poppins aplicada */
  }
  
  .lermais {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .botao-leiamais {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Poppins', sans-serif; /* Poppins aplicada */
  }
  
  .botao-leiamais:hover {
    background-color: #0056b3;
  }
  </style>
  