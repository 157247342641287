<template>
    <div class="editor-wrapper">
      <h2 class="content-block">Suporte CWS</h2>
      <div class="editor-container">
        <DxHtmlEditor
          ref="htmlEditor"
          :height="310"
          :toolbar="toolbarConfig"
          :value="editorContent"
          :media-resizing="{enabled: true}"
          @value-changed="onInput"
        />
        <div class="file-upload">
          <label for="file">Selecione um anexo:</label>
          <input type="file" id="file-uploader2" @change="onFileChange" />
        </div>
        <div class="box">
          <DxButton 
            text="Fechar"
            type="danger"
            class="close-button"
            @click="close_suporte()"
          />
          <DxButton 
            text="Enviar"
            type="success" 
            @click="cadastraSuporte" 
            class="close-button"
          />
        </div>
        <div class="editor-placeholder">
          Escreva aqui a sua dúvida.
        </div>
      </div>
    </div>
  </template>
  <script>
  import 'devextreme/ui/html_editor';
  import { DxHtmlEditor } from 'devextreme-vue/html-editor';
  import DxButton from 'devextreme-vue/button'
  import api from '../api/api'
  
  export default {
    components: {
      DxHtmlEditor,
      DxButton
    },
    data() {
      return {
        editorContent: '', // Conteúdo do editor
        selectedFile: null, // arquivo selecionado
        toolbarConfig: {
          items: [
            'undo', 'redo', 'separator', 'bold', 'italic', 'underline', 'separator',
            'alignLeft', 'alignCenter', 'alignRight'
          ],
        },
        isPlaceholderVisible: true,
      };
    },
    methods: {
      // Atualiza placeholder
      onInput(e) {
        var content = e.value.trim();
        this.editorContent = content;
        this.isPlaceholderVisible = content === '';
      },
      // pega o arquivo
      onFileChange(e) {
        this.selectedFile = e.target.files[0];
      },
      close_suporte(){
        this.$router.push('/login-form');
      },
      async cadastraSuporte(){
        var user = JSON.parse(localStorage.getItem('user'))
        if (!user.cd_usuario) {
          console.error('sem empresa');
          return;
        }
  
        var empresa = user.cd_usuario;
        let fileUrl = null
  
        if (this.selectedFile) {
          var uploadResult = await api.upload_file(this.selectedFile, `upload-file2/${empresa}`);
          if (uploadResult.code === 0) {
            fileUrl = uploadResult.url; // URL do arquivo salvo
          } else {
            console.error('Erro ao fazer upload do arquivo:', uploadResult.Msg);
            return;
          }
        }
        var suporteData = {
          cd_empresa: empresa,
          nm_descricao: this.editorContent,
          nm_arquivo: fileUrl
        };
        var suporteResult = await api.post('cadastra_suporte', suporteData)
        if (suporteResult.code === 0) {
          console.log('Suporte cadastrado com sucesso!');
        } else {
          console.log('Erro ao cadastrar o suporte.');
        }
      }
    },
  }
  </script>
  
  <style>
  .editor-wrapper {
    
  }
  
  .editor-container {
    position: relative;
  
  }
  
  .file-upload {
    background-color: white;
    padding: 10px;;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 15px 0px
  }
  
  .file-upload input {
    margin-top: 10px;
  }
  
  .editor-placeholder {
    position: absolute;
    top: 50px;
    left: 5px;
    color: gray;
    font-size: 16px;
    font-style: italic;
    pointer-events: none;
    z-index: 1;
    padding: 10px;
  }
  
  .dx-htmleditor-content {
    padding: 10px;
  }
  
  .dx-htmleditor-content p {
    color: #000 !important;
  }
  </style>