<template>
    <div>
        <ativos_info :ativo="ativo_selecionado" :ativos="[]"/>
        <div v-show="show_add_ativo">
            <DxSelectBox
                placeholder="Ativo"
                :data-source="ativos_select"
                v-model="cd_ativo"
                display-expr="nm_item_serial"
                value-expr="cd_ativo"
                search-mode="contains"
                search-expr="nm_item_serial"
                :search-enabled="true"
                @value-changed="onChangeAtivo"
            />
            <br>
            <DxNumberBox
                placeholder="Posição inicial"
                v-model="cd_item_u"
                :show-spin-buttons="true"
                :show-clear-button="true"
                :min="0"
            />
            <br>
            <DxButton 
                icon="check" 
                type="success" 
                text="Adicionar" 
                @click="adicionar_ativo"/>
        </div>
        <!--div v-html-->
        <div v-show="show_rack && load" class="div-rack">
            <div class="rack">
                <div v-for="(ativo) in ativos_hack" v-bind:key="ativo">
                    <div v-if="ativo.u != undefined" class="rack_item"></div>
                    <div v-else class="rack_item_imagem"><img :height="(ativo.qt_us*15) + 'px'" width="170px" :src="ativo.nm_caminho_imagem" alt="" @click="ativo_selecionado = ativo"></div>
                </div>
            </div>
            <div class="racknum">
                <div v-for="i in posicoes" v-bind:key="i">
                    <div class="rack_num">{{ i }}</div>
                </div>
            </div>
        </div>
        <div v-show="show_lista">
            <DxDataGrid
                :data-source="ativos_selecionados"
                key-expr="cd_item_u"
                :columns="colunas"
                :show-borders="true"
                :focused-row-enabled="true"
                :row-alternation-enabled="true"
                :column-auto-width="true"
                :selection="{ mode: 'single' }"
                @selection-changed="changeAtivoGrid"
            >
                <DxHeaderFilter :visible="true"/>
                <DxScrolling column-rendering-mode="virtual"/>
                <DxSearchPanel
                  :visible="true"
                  :highlight-case-sensitive="true"
                />
                <template #img="{data}">
                    <button class="imgbutton" @click="OpenImage(data.value)"><img :src="data.value" max-width="50" height="45" alt=""></button>
                </template>
            </DxDataGrid>
        </div>
        <div v-show="show_historico">
            HISTÓRICO
        </div>
        <br>
        <hr class="rounded">
        <br>
        <DxButton
            icon="verticalaligntop" 
            type="default" 
            class="button_popup"
            text="Visualizar Rack" 
            @click="mostra_rack"/>
        <DxButton
            icon="alignleft" 
            class="button_popup"
            type="default" 
            text="Visualizar em Lista"
            @click="mostra_lista"/>
        <DxButton 
            class="button_popup"
            icon="add" 
            type="default" 
            text="Adicionar ativo"
            @click="add_ativo"/>
    </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import DxSelectBox from 'devextreme-vue/select-box';
import api from '@/api/api'
import { DxNumberBox } from 'devextreme-vue/number-box';
import {DxDataGrid,DxHeaderFilter,DxSearchPanel,DxScrolling} from 'devextreme-vue/data-grid';
import ativos_info from './ativo_selecionado_sonda'

    export default{
        name:'something_else',
        props:{
            fase_localizacao: Object,
            ativos: Array,
            cd_fase_localizacao: Number
        },
        data(){
            return{
                fase_localizacao_selecionada: [],
                ativos_selecionados: [],
                ativos_hack: [],
                open_popup: false,
                button_cancelar: {
                  width: 15,
                  icon: 'close',
                  type:'danger',
                  onClick: ()=>{
                    this.open_popup = false
                  }
                },
                posicoes: [],
                ativo_selecionado: {},
                show_add_ativo: true,
                show_rack: false,
                show_historico: false,
                largura_popup:35,
                show_lista: false,
                ativos_select: [],
                cd_ativo: 0,
                cd_item_u: '',
                colunas: [
                    {
                        'caption': "Posição vertical",
                        'dataField':"cd_item_u",
                    },
                    {
                        'caption': "Descrição do ativo",
                        'dataField':"nm_descricao",
                    },
                    {
                        'caption': "Número de série",
                        'dataField':"nm_serial_number",
                    },
                    {
                        'caption': "Ocupação (Us)",
                        'dataField':"qt_us",
                    },
                    {
                        'caption': "Imagem do ativo",
                        'dataField':"nm_caminho_imagem",
                        'cellTemplate': 'img'
                    },
                ],
                load: false
            }
        },
        async created(){
            this.fase_localizacao_selecionada = this.fase_localizacao
            console.log('FASE LOCALIZACAO SELECIONADA',this.cd_fase_localizacao)
            this.ativos_selecionados = this.ativos
            this.valida_ativo()
            this.ativos_select = await api.get('consulta_ativo_sem_posicao_horizontal', [])
        },
        methods:{
            changeAtivoGrid({selectedRowsData}){
                this.ativo_selecionado = selectedRowsData[0]
            },
            async onChangeAtivo(){
                this.ativo_selecionado = await api.get('consulta_ativo_item', [this.cd_ativo])
                console.log('ATIVO', this.ativo_selecionado[0])
                this.ativo_selecionado = this.ativo_selecionado[0]
            },
            gera_relatorio(){
                alert('GERAR RELATORIO')
            },
            historico(){
                this.show_rack = false
                this.show_lista = false
                this.show_add_ativo = false
                this.show_historico = true
            },
            add_ativo(){
                this.show_rack = false
                this.show_lista = false
                this.show_add_ativo = true
                this.largura_popup = 100
                this.ativo_selecionado = {}
            },
            mostra_lista(){
                this.show_rack = false
                this.show_lista = true
                this.show_add_ativo = false
                this.largura_popup = 100
                this.ativo_selecionado = {}
            },
            mostra_rack(){
                this.show_rack = true
                this.show_lista = false
                this.show_add_ativo = false
                this.largura_popup = 100
                this.ativo_selecionado = {}
            },
            valida_ativo(){
                for(var i = 1; i <= this.fase_localizacao_selecionada.qt_us; i++){
                    this.posicoes.push(i)
                    var item = this.ativos_selecionados.filter((a)=> a.cd_item_u == i)
                    console.log('ITEM', item)
                    if(item.length != 0){
                        this.ativos_hack.push(item[0])
                    }
                    else{
                        this.ativos_hack.push({u:i})
                    }
                }
                //this.ativos_hack = this.ativos_hack.filter((este, id)=> this.ativos_hack.indexOf(este)===id);
                this.ativos_hack.reverse()
                this.posicoes.reverse()
                console.log('ATIVOS RACK',this.ativos_hack)
                this.load = true
            },
            async adicionar_ativo(){
                var resp = await api.get('add_ativo_fase_localizacao_vertical',[this.cd_ativo, this.cd_item_u, this.cd_fase_localizacao])
                this.ativos_selecionados = await api.get('consulta_ativos_fase_localizacao', [this.cd_fase_localizacao])
                this.valida_ativo()
                console.log(resp)
            }
        },
        components:{
            DxButton,
            DxSelectBox,
            DxNumberBox,
            DxDataGrid,
            DxHeaderFilter,DxSearchPanel,DxScrolling,
            ativos_info
        }
    }
</script>
<style>
.div-rack{
    display: flex;
}
.infos_ativo{
  display: flex;
  align-items: center;
}
.span_p{
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.rack_num {
    height: 15px;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
    text-align: right;
}
.racknum{
    margin-top: 25px;
    width: 30px;
}
.rack {
    margin-top: 20px;
    border: 5px solid gray;
    width: 180px;
}
.rack_item {
    height: 15px;
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.rack_item_imagem{
  height: 15px;
  width: 180px;
}
hr.rounded {
  border-top: 3px solid #1185B2;
  border-radius: 3px;
}

.button_popup{
    width: 17%;
}
</style>