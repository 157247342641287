<template>
   <Navbar/>
    <div class="cont">
        
      <div class="posts">
        <div class="box-blog">
          <div v-for="post in posts" :key="post.ID" class="card">
            <PostTemplate :post="post"/>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import PostTemplate from '../blog/post_template';
  import api from '../api/api';
  import Navbar from './navbar-blog.vue'
  
  export default {
    components: {
      PostTemplate,
      Navbar,
      // About
    },
    setup() {
      const posts = ref([]);
      const isMenuActive = ref(false);
  
      const fetchPosts = async () => {
        try {
          posts.value = await api.login('consulta_post', []);
        } catch (error) {
          console.error('Failed to fetch posts:', error);
        }
      };
  
      const toggleMenu = () => {
    isMenuActive.value = !isMenuActive.value;
    const menuToggle = document.querySelector('.menu-toggle');
    if (menuToggle) {
        menuToggle.classList.toggle('open');
    }
};
  
      onMounted(() => {
        fetchPosts();
      });
  
      return {
        posts,
        isMenuActive,
        toggleMenu,
      };
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');
  
  html {
      font-size: 16px;
      overflow-x: hidden;
      font-family: 'Poppins', sans-serif; /* Certifique-se de que a fonte seja aplicada globalmente */
      background-color: #FFFFFF;
  }
  
  body {
      font-family: 'Poppins', sans-serif; /* Aplicando Poppins no body */
      background-color: #FFFFFF;
      overflow-x: hidden;
  }
  
  * {
      margin: 0;
      padding: 1rem 0;
      box-sizing: border-box;
      font-family: 'Poppins', sans-serif; /* Garantindo que todos os elementos usem Poppins */
  }
  
  .cont {
      background-color: #FFFFFF;
      padding: 1rem 0rem;
  }
  
  .posts {
      max-width: 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem; 
      justify-content: center; 
  }
  
  .head {
      width: 100%;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      background: #f2f5f9;
  }
  
  .bv {
      position: absolute;
      margin: 0 auto;
      top: 8rem;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
  }
  
  .box-blog {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      justify-content: center; 
      background: #FFFFFF;
      margin-top: 5rem;
  }
  </style>
  