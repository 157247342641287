<template>
    <div class="pai_gsa">
        <div class="barra_pastas">
            <DxButton class="pastas" v-for="(cam, index) in caminho" :key="cam" @click="click_caminho(index)" :text="cam" :icon="cam.includes('.') ? 'file' : 'folder'" />
        </div>
        <div v-show="!show_arquivo" class="arquivos_pastas">
            <br>
            <div v-for="arq in arquivos" :key="arq.nome" class="arquivo-item">
                <div class="arquivo-detalhes">
                    <DxButton 
                        class="button_sga" 
                        style="background-color: transparent;" 
                        :icon="arq.isDirectory ? 'folder' : 'file'" 
                        :text="arq.nome" 
                        @click="carrega_dir(arq.nome)"
                    />
                    <!-- Alinha a data à direita -->
                    <span class="modification-time">
                        {{ new Date(arq.modificationTime).toLocaleString() }}
                    </span>
                </div>
            </div>
        </div>
        <div class="conteudo_arquivo" v-show="show_arquivo">
            <div class="cima_arquivo">
                <DxButton style="background-color: transparent" icon="arrowleft" @click="click_caminho(caminho.length - 2)" />
                <DxButton style="background-color: #2585b2" type="success" icon="parentfolder" @click="atualizar_versao()" text="Atualizar nova versão" />
                <DxButton style="background-color: #2585b2" type="success" icon="save" @click="baixar_versao()" text="Baixar versão" />
            </div>
            <div class="conteudo_arquivo_texto">
                {{ conteudo_arquivo }}
            </div>
        </div>
        <DxPopup v-if="show_popup" :visible="show_popup" height="100%" width="50%">
            <DxToolbarItem widget="dxButton" toolbar="top" location="after" :options="button_cancelar" />
            <div class="form-group">
                <DxTextBox label="Título" v-model="nm_titulo" />
            </div>
            <div class="form-group">
                <DxTextBox label="Descrição" v-model="nm_descricao" />
            </div>
            <div class="form-group">
                <DxSelectBox label="Pessoa" v-model="nm_pessoa" :items="pessoas" displayExpr="nome" valueExpr="nome" />
            </div>
            <div class="form-group">
                <DxSelectBox label="Empresa" v-model="cd_empresa" :items="empresa" displayExpr="nm_empresa" valueExpr="cd_empresa" />
            </div>
            <div class="form-group">
                <DxTextBox label="Link" v-model="nm_link" />
            </div>
            <div class="form-group">
                <DxDateBox label="Data" v-model="data_" displayFormat="dd/MM/yyyy HH:mm" type="datetime" />
            </div>
            <input type="file" id="file-uploader" @change="showimg" />
            <DxButton style="background-color: #2585b2" type="success" icon="save" @click="upload_versao()" text="Confirmar Upload" />
        </DxPopup>
    </div>
</template>

<script>
import api from '@/api/api';
import { DxSelectBox } from 'devextreme-vue';
import DxButton from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box'

export default {
    data() {
        return {
            arquivos: [],
            caminho: [],
            conteudo_arquivo: '',
            show_arquivo: false,
            show_popup: false,
            usuario: {},
            nm_titulo:'',
            nm_descricao:'',
            nm_pessoa:'',
            nm_link:'',
            data_:new Date(),
            pessoas: [
              { nome: "Mateus"},
              { nome:"Henrique"},
              { nome: "Vinicius"}
            ],
            empresa: '',
            button_cancelar: {
                width: 15,
                icon: 'close',
                type: 'danger',
                onClick: () => {
                    this.show_popup = false;
                }
            },
            nm_alteracao: ''
        };
    },
    async created() {
        await this.carrega_dados();
    },
    methods: {
        async carrega_dados() {
            const response = await api.get('consulta_arquivos', ['home']);
            this.arquivos = response.arquivos;
            this.empresa = await api.login('consulta_empresa',[])
            this.usuario = JSON.parse(localStorage.getItem('user'));
        },
        async carrega_dir(dir) {
            this.caminho.push(dir);
            if (dir.includes('.')) {
                this.show_arquivo = true;
                // Abre o arquivo
                this.conteudo_arquivo = await api.read_file('le_arquivo', [this.caminho.join('+')]);
            } else {
                this.show_arquivo = false;
                this.conteudo_arquivo = '';
                const response = await api.get('consulta_arquivos', [this.caminho.join('+')]);
                this.arquivos = response.arquivos;
            }
        },
        async baixar_versao() {
            api.download_file('baixa_arquivo', [this.caminho.join('+')], this.caminho[this.caminho.length - 1]);
        },
        async atualizar_versao() {
            this.show_popup = true;
        },
        async upload_versao() {
            try {
                const arq = document.getElementById('file-uploader').files[0];
                if (!arq) {
                    alert("Selecione um arquivo para upload");
                    return;
                }
                const resp2 = await api.get(`atualiza_arquivo`, [this.caminho.join('+')]);
                await api.upload_file(arq, `atualiza_arquivo/${[this.caminho.join('+')]}`);
                console.log(resp2);
                this.show_popup = false;
                await this.carrega_dados();

                // cadastra no calendário
                await api.post('cadastra_calendario', {
                    nm_titulo: this.nm_titulo,
                    nm_descricao: this.nm_descricao,
                    nm_pessoa: this.nm_pessoa,
                    nm_link: this.nm_link,
                    data_: this.data_,
                    cd_empresa: this.cd_empresa
                });
                alert('Arquivo carregado e evento registrado no calendário com sucesso!');
            } catch (error) {
                console.error("Erro durante o upload:", error);
                console.log("Erro durante o upload do arquivo.");
            }
        },
        async click_caminho(index) {
            this.show_arquivo = false;
            this.conteudo_arquivo = '';
            if (index === 0) {
                this.caminho.splice(index);
                await this.carrega_dados();
            } else {
                this.caminho.splice(index + 1);
                const response = await api.get('consulta_arquivos', [this.caminho.join('+')]);
                this.arquivos = response.arquivos;
            }
        }
    },
    components: {
        DxButton,
        DxPopup,
        DxToolbarItem,
        DxTextBox,
        DxSelectBox,
        DxDateBox
    }
};
</script>

<style>
.pai_gsa {
    padding-left: 20%;
    padding-right: 20%;
}
.barra_pastas {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
}
.arquivos_pastas {
    border: 1px solid black;
    border-radius: 10px;
}
.pastas {
    margin: 5px;
}
.conteudo_arquivo {
    white-space: pre-wrap;
    margin-left: -100px;
    margin-right: -100px;
    background-color: white;
    border-radius: 10px;
}
.conteudo_arquivo_texto {
    padding: 10px;
}
.button_sga {
    height: 20px;
    margin: 5px;
    font-size: small;
}
.cima_arquivo {
    background-color: #929292;
    border-radius: 10px 10px 0 0;
    width: 100%;
    color: white;
    padding: 20px;
}
.modification-time {
    font-size: small;
    color: gray;
    margin-left: 10px;
}
.arquivo-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.arquivo-detalhes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
</style>