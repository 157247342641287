<template>
  <div :class="['navbar', { 'navbar-hidden': isHidden }]" id="navbar">
    <img
      src="http://191.252.156.73/logo_empresa/logo_.png"
      class="logo"
      alt="Company Logo"
      @click="navigateToBlog"
    />
    <div class="navbar-content">
      <ul class="list">
        <li class="li-item" @click="navigateToBlog">Home</li>
        <li class="li-item">Quem Somos</li>
        <li class="li-item">Soluções</li>
        <li class="li-item" @click="navigateToBlog">Blog</li>
        <li class="li-item">Contato</li>
      </ul>
    </div>
    <nav class="menu" v-if="windowWidth <= 725">
      <div class="menu-lines" :class="{ active: isMenuActive }" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul :class="{ 'menu-active': isMenuActive }" class="menu-list">
        <li @click="navigateToBlog">Home</li>
        <li>Quem Somos</li>
        <li>Soluções</li>
        <li @click="navigateToBlog">Blog</li>
        <li>Contato</li>
      </ul>
    </nav>
  </div>
</template>





<script>
export default {
  data() {
    return {
      isMenuActive: false,
      isHidden: false,
      lastScrollY: 0,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    },
    navigateToBlog() {
      this.$router.push({ path: '/blog' });
    },
    handleScroll() {
      const currentScrollY = window.scrollY;

      if (currentScrollY > this.lastScrollY) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }

      this.lastScrollY = currentScrollY;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};

</script>



<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600&display=swap');

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
  justify-content: space-between;
  transition: transform 0.8s ease-in-out;
}

.navbar-hidden {
  transform: translateY(-100%);
}

body {
  font-family: 'Poppins', sans-serif;
}

.logo {
  max-width: 140px;
  padding: 0.5rem;
  height: auto;
  cursor: pointer;
}

.logo:hover {
  filter: opacity(0.8);
}

.list {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 3rem;
}

.navbar-content {
  position: absolute;
  right: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  flex: 1;
  height: 3rem;
  border-radius: 1rem;
}

.li-item {
  cursor: pointer;
  font-size: 1rem;
  color: black;
  white-space: nowrap;
}

a {
  text-decoration: none;
  color: black;
}


.menu {
  position: absolute;
  top:0;
  bottom:0;
  right: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 1.5rem;
  cursor: pointer;
  z-index: 1010;
  transition: transform 0.3s ease;
}

.menu-lines:hover,
.menu-lines.active {
  transform: scale(1.2);
}


.menu-toggle {
  cursor: pointer;
}

.menu-lines span {
  margin-bottom: 5px;
  display: block;
  width: 35px;
  height: 3px;
  background-color: rgb(48, 48, 48);
  transition: all 0.3s ease-in-out;
  border-radius: 2px;
}


.menu-list {
  position: fixed;
  top: 3rem;
  right: -100%;
  width: 200px;
  height: 100vh;
  background-color: white;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding-left: 1rem;
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.menu-active {
  right: 0;
}

.menu-list li {
  font-size: 1.2rem;
  cursor: pointer;
  transition: filter 1s ease;
  transition: scale 1s ease;
  font-weight: 500;
}

.menu-list li:hover{
  filter:opacity(0.8);
  transform: scale(1.05)
}


@media (max-width: 725px) {
  .navbar {
    padding: 0 1rem;
  }

  .logo {
    max-width: 6.25rem;
    margin-left: 1rem;
  }

  .list {
    display: none;
  }

  .menu-lines {
    display: block;
  }
}

@media (max-width: 480px) {
  .navbar {
    height: 4rem;
  }

  .logo {
    max-width: 5rem;
    margin-left: 1rem;
  }

  .menu-list li {
    padding: 0.5rem;
    font-size: 1rem;
  }
}
</style>
